import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import PanelsList from "./lists/PanelsList";
import UsersList from "./lists/UsersList";
import UsersListByPanel from "./lists/UsersListByPanel";
import PanelsListByUser from "./lists/PanelsListByUser";

const ManageExistingPage = (token) => {
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [viewMode, setViewMode] = useState("panel");

  const handleViewModeChange = (val) => {
    setViewMode(val);
    setSelectedPanel(null);
    setSelectedUser(null);
  };

  return (
    <Container fluid>
      {/* <Row className="mt-3">
                <Col>
                    <ToggleButtonGroup
                        type="radio"
                        name="viewMode"
                        value={viewMode}
                        onChange={handleViewModeChange}
                    >
                        <ToggleButton
                            id="toggle-panel"
                            value="panel"
                            variant={viewMode === 'panel' ? 'primary' : 'outline-primary'}
                        >
                            Search by Panel
                        </ToggleButton>
                        <ToggleButton
                            id="toggle-user"
                            value="user"
                            variant={viewMode === 'user' ? 'primary' : 'outline-primary'}
                        >
                            Search by User
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Col>
            </Row> */}

      <Row className="mt-3">
        {viewMode === "panel" && (
          <>
            <Col md={selectedPanel ? 6 : 12}>
              <PanelsList onPanelSelect={setSelectedPanel} token={token} />
            </Col>
            {selectedPanel && (
              <Col md={6}>
                <UsersListByPanel panel={selectedPanel} token={token} />
              </Col>
            )}
          </>
        )}

        {viewMode === "user" && (
          <>
            <Col md={selectedUser ? 6 : 12}>
              <UsersList onUserSelect={setSelectedUser} />
            </Col>
            {selectedUser && (
              <Col md={6}>
                <PanelsListByUser user={selectedUser} />
              </Col>
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

export default ManageExistingPage;
